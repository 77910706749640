import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { kcContext } from './keycloakTheme/kcContext';

// const App = lazy(() => import("./App"));
const KcApp = lazy(() => import('./keycloakTheme/KcApp'));

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>{kcContext === undefined ? null : <KcApp kcContext={kcContext} />}</Suspense>
  </StrictMode>
);
